<template>
  <b-overlay :show="isFetching || isDeleting">
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h6>
          {{ `${$t('fields.result')} (${cashbackSettings.length})` }}
        </h6>
        <b-button
          variant="primary"
          @click="toggleModal({})"
        >
          <i class="uil-plus mr-1"></i>
          {{ $t('buttons.add') }}
        </b-button>
      </div>

      <b-table
        :items="cashbackSettings"
        :fields="fields"
        responsive
        show-empty
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(agentUsername)="data">
          {{ data.value }}
        </template>
        <template #cell(isLossBalance)="data">
          <span v-if="data.value">
            {{ $t('cashback.is_loss_balance') }}
          </span>
          <span v-if="data.item.isBetBalance">
            {{ $t('cashback.is_bet_balance') }}
          </span>
        </template>
        <template #cell(intervalUnit)="data">
          <span v-if="data.value === 'day'">
            {{ $t('utils.periods.day') }}
          </span>
          <span v-else-if="data.value === 'week'">
            {{ $t('utils.periods.week') }}
          </span>
          <span v-else-if="data.value === 'month'">
            {{ $t('utils.periods.month') }}
          </span>
        </template>
        <template #cell(cashbackValue)="data">
          {{ data.value | currency }}
          {{ data.item.cashbackType === 'percentage' ? '%' : '฿' }}
        </template>
        <template #cell(maximumReturn)="data">
          {{ data.value | money }}
        </template>
        <template #cell(minimumReturn)="data">
          {{ data.value | money }}
        </template>
        <template #cell(isIncludePromotion)="data">
          <b-badge :variant="data.value ? 'primary' : 'danger'">
            {{ data.value ? $t('fields.yes') : $t('fields.no') }}
          </b-badge>
        </template>
        <template #cell(isActive)="data">
          <b-badge :variant="data.value ? 'success' : 'danger'">
            {{
              data.value
                ? $t('utils.status.active')
                : $t('utils.status.inactive')
            }}
          </b-badge>
        </template>
        <template #cell(gameType)="data">
          <b-badge variant="info">
            {{
              data.value
            }}
          </b-badge>
        </template>
        <template #cell(id)="data">
          <button
            type="button"
            class="btn btn-info btn-sm"
            @click="toggleModal(data.item)"
          >
            {{ $t('buttons.edit') }}
          </button>
          <!-- <b-dropdown
              variant="info"
              size="sm"
              dropleft
            >
              <template #button-content>
                <i class="uil uil-cog"></i>
              </template>
              <b-dropdown-item @click="toggleModal(data.item)">
                <i class="uil uil-edit-alt mr-2"></i>
                {{ $t('buttons.edit')}}
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                @click="onDelete(data.value)"
              >
                <i class="uil uil-trash-alt mr-2"></i>
                <span>
                  {{  $t('buttons.remove')}}
                </span>
              </b-dropdown-item>
            </b-dropdown> -->
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
      </b-table>
    </b-card>
    <b-modal
      v-model="isModalActive"
      :title="
        `${
          selectedValue.id
            ? $t('routes.EDIT_CASHBACK')
            : $t('routes.CREATE_CASHBACK')
        }`
      "
      size="lg"
      hide-footer
    >
      <CashbackForm
        v-model="selectedValue"
        :is-loading="isAdding || isUpdating"
        @submit="onSubmit"
        @cancel="onCloseModal"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'CashbackMaster',
  components: {
    CashbackForm: () => import('../forms/cashback-form'),
  },
  data() {
    return {
      isModalActive: false,
      selectedValue: {},
      fields: [
        '#',
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'agentUsername',
          label: `${this.$t('agent.name')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'isLossBalance',
          label: `${this.$t('fields.type')}`,
          thStyle: {
            minWidth: '150px',
          },
        },

        {
          key: 'cashbackValue',
          label: `${this.$t('cashback.cashback_value')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'maximumReturn',
          label: `${this.$t('cashback.max_cashback')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'minimumReturn',
          label: `${this.$t('cashback.min_cashback')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'maximumCashbackPerRound',
          label: `${this.$t('cashback.max_cashback_per_round')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'minimumCashbackPerRound',
          label: `${this.$t('cashback.min_cashback_per_round')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
        // {
        //   key: 'turningPoint',
        //   label: `${this.$t('cashback.turnover_point')}`,
        //   class: 'text-right',
        //   thStyle: {
        //     minWidth: '130px',
        //   },
        // },
        {
          key: 'gameType',
          label: `${this.$t('cashback.game_type')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '80px',
          },
        },
        {
          key: 'agentCredit',
          label: `${this.$t('cashback.agent_credit')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '130px',
          },
        },
        // {
        //   key: 'isIncludePromotion',
        //   label: `${this.$t('cashback.is_include_promotion')}`,
        //   class: 'text-center',
        //   thStyle: {
        //     minWidth: '140px',
        //   },
        // },
        {
          key: 'startDate',
          label: `${this.$t('cashback.start_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'intervalUnit',
          label: `${this.$t('cashback.interval_unit')}`,
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'isActive',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
        },
        {
          key: 'id',
          label: '-',
          width: '100',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.cashback.isFetchingCashbackSettings,
      isAdding: (state) => state.cashback.isAddingCashbackSettings,
      isUpdating: (state) => state.cashback.isUpdatingCashbackSettings,
      isDeleting: (state) => state.cashback.isDeletingCashbackSettings,
    }),
    ...mapGetters(['cashbackSettings']),
  },
  created() {
    this.fetchCashbackSettings()
  },
  methods: {
    ...mapActions([
      'fetchCashbackSettings',
      'addCashbackSettings',
      'updateCashbackSettings',
      'deleteCashbackSettings',
    ]),
    toggleModal(value) {
      this.isModalActive = !this.isModalActive
      this.selectedValue = value
    },
    onCloseModal() {
      this.isModalActive = false
    },
    async onSubmit(form) {
      if (form.id) {
        await this.updateCashbackSettings({
          id: form.id,
          form,
        })
        this.onCloseModal()
      } else {
        await this.addCashbackSettings(form)
        this.onCloseModal()
      }
    },
    onDelete(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteCashbackSettings(id)
          }
        })
    },
  },
}
</script>
